.cta-btn {
  display: flex;
  justify-content: center;
}

.gallery-container {
  position: relative;

  .scroll-downs {
    position: absolute;
    top: 70%;
    right: 0;
    bottom: 0px;
    left: 0;
    margin: auto;
    z-index: 20;
    opacity: 0.4;

    width :34px;
    height: 55px;
  }
  img.pointer {
    max-width: 50px;
    margin-top: -30px;
    margin-left: -120px;
    opacity: 0.5;
    z-index: 30;
    -webkit-animation: slide-right 2.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) infinite alternate backwards;
    animation: slide-right 2.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) infinite alternate backwards;
  }
  .mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
    bottom: -50px;
    -webkit-animation: slide-right 2.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) infinite alternate backwards;
    animation: slide-right 2.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) infinite alternate backwards;
  }
  .scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-left: -1em;
  margin-right: -1em;
  position: relative;

  .card  {
    display: inline-block;
  }

  .gallery-item {
    background: none;
  }

  .art-display {
    max-width: 400px;
  }

}


.photobanner-container {
  height:350px;
  position:relative;
  overflow:hidden;
}

.photobanner {
  position:absolute;
  top:0px;
  left:0px;
  overflow:hidden;
  white-space: nowrap;
  animation: bannermove 20s linear infinite;
}

.photobanner img {
  margin: 0 0.1em;
  height: 350px;
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
