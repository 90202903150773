.rm2 {
  display: block;
  position: relative;
  top: -50px;
  height: 100px;
  //background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1652 234" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(244,122,37,1)" d="M 0 69 C 72 69 108 32 180 32 L 180 32 L 180 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(244,1,37,1)" d="M 179 32 C 251.8 32 288.2 71 361 71 L 361 71 L 361 0 L 179 0 Z" stroke-width="0"></path> <path fill="rgba(244,122,37,1)" d="M 360 71 C 434.8 71 472.2 32 547 32 L 547 32 L 547 0 L 360 0 Z" stroke-width="0"></path><path fill="rgba(244,122,37,0.3)" d="M 546 32 C 656.4 32 711.6 91 822 91 L 822 91 L 822 0 L 546 0 Z" stroke-width="0"></path><path fill="rgba(244,122,37,1)" d="M 821 91 C 903.8 91 945.2 36 1028 36 L 1028 36 L 1028 0 L 821 0 Z" stroke-width="0"></path><path fill="rgba(18, 63, 96, 1)" d="M 1027 36 C 1112.6 36 1155.4 99 1241 99 L 1241 99 L 1241 0 L 1027 0 Z" stroke-width="0"></path><path fill="rgba(18, 63, 96, 1)" d="M 1240 99 C 1303.2 99 1334.8 59 1398 59 L 1398 59 L 1398 0 L 1240 0 Z" stroke-width="0"></path><path fill="rgba(18, 63, 96, 1)" d="M 1397 59 C 1458.2 59 1488.8 103 1550 103 L 1550 103 L 1550 0 L 1397 0 Z" stroke-width="0"></path><path fill="rgba(18, 63, 96, 1)" d="M 1549 103 C 1590.2 103 1610.8 58 1652 58 L 1652 58 L 1652 0 L 1549 0 Z" stroke-width="0"></path></svg>');
  //background-image: url('../../assets/elf/roadmap-03.svg');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -4;
}

@media (max-width: 1900px) {
  .rm2 {
    background-size: contain;
    transform: rotate(180deg) rotateY(180deg);
  }
}

.custom-shape-divider-top-1638510216 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1638510216 svg {
  position: relative;
  display: block;
  width: calc(104% + 1.3px);
  height: 412px;
}

.custom-shape-divider-top-1638510216 .shape-fill {
  fill: #E4D5D5;
}

.custom-shape-divider-top-1638510451 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1638510451 svg {
  position: relative;
  display: block;
  width: calc(104% + 1.3px);
  height: 412px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1638510451 .shape-fill {
  fill: #E4D5D5;
}

.custom-shape-divider-bottom-1638510619 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1638510619 svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 123px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1638510619 .shape-fill {
  fill: #E4D5D5;
}

.custom-shape-divider-top-1638510679 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1638510679 svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 123px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1638510679 .shape-fill {
  fill: #E4D5D5;
}

.custom-shape-divider-top-1638510755 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1638510755 svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 123px;
}

.custom-shape-divider-top-1638510755 .shape-fill {
  fill: #E4D5D5;
}

$options: (
        1: #e25252,
        2: #c95db2,
        3: #674ead,
        4: #53a7e0,
        5: #56b784,
        6: #afaf43,
        7: #ef6111
);

$elements: 9;

@function roadmap-color-index($index) {
  $color: map-get($options, $index);
  @return $color;
}

@for $i from 1 to $elements {

  div.roadmap-station:nth-child(#{$i + 7}) {
    //background-image: linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0));
    background-color: roadmap-color-index($i);
    position: relative;
  }
}

.roadmap-station {
  //background-image: url("../../assets/roadmap-stripe.png"), url("../../assets/elf/dirt-road-section.png");
  background-image: url("../../../assets/projects/elf/dirt-road-section.png");
  background-size: 250px;
  background-position-x: 1%;
  background-attachment: fixed, fixed;
  background-repeat: repeat-y;
  background-position-y: 20%;
  height: 100%;
  position: relative;
  padding: 2em 1em
}



